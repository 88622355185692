import React from 'react'
import loadable from '@loadable/component'
import { useOverlayStore } from '../stores/overlay'

import Icon from '../svg/icon-cross.svg'

const PortableText = loadable(() => import('./PortableText'))

const Popup = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const { title, body, closeOverlay } = useOverlayStore()

  return (
    <>
      <header className="sticky top-0 left-0 flex justify-between p-4 bg-gray-100 lg:p-8 z-1">
        {title && <h2 className="text-lg font-bold leading-none">{title}</h2>}
        <button
          type="button"
          className="flex items-center leading-none text-gray-200"
          onClick={closeOverlay}
        >
          <span className="mr-2 mt-3px font-sm">{language == 'en' ? 'Close' : 'Chuidi'}</span>
          <Icon width="18" height="18" />
        </button>
        <span className="absolute bottom-0 border-b border-gray-200 border-solid inset-x-4 lg:inset-x-8" />
      </header>
      <main className="p-4 lg:p-8">
        {body && <PortableText blocks={body} isBasic />}
      </main>
    </>
  )
}

export default Popup
