import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import SimpleBar from 'simplebar-react'
import { IoMdClose } from 'react-icons/io'
import { findActiveIndex } from '../../helpers/overlay'
import { useOverlayStore } from '../../stores/overlay'
import { useLockBodyScroll } from '../../helpers/useLockBodyScroll'

import Form from '../Form'
import Popup from '../Popup'
import Icon from '../../svg/icon-cross.svg'

const Content = () => {
  const [submitted, setSubmitted] = useState(false)
  const { overlayDisplay, contentType, body, closeOverlay } = useOverlayStore()
  const content = useRef()
  const width = contentType === 'form' ? 'md:max-w-lg' : 'md:max-w-screen-sm'

  const handleEscape = evt => {
    if (!overlayDisplay) return
    if (evt.key === 'Escape') closeOverlay()
  }

  const handleTab = evt => {
    // Select all focusable elements
    const focusableElements = content.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="tel"], input[type="email"], input[type="date"], select'
    )

    if (!evt.shiftKey) {
      let nextIndex = findActiveIndex(focusableElements)

      // If activeIndex + 1 larger than array length focus first element otherwise focus next element
      nextIndex + 1 === focusableElements.length
        ? (nextIndex = 0)
        : (nextIndex += 1)

      focusableElements[nextIndex].focus()

      return evt.preventDefault()
    }

    if (evt.shiftKey) {
      let prevIndex = findActiveIndex(focusableElements)

      // if activeIndex - 1 less than 0 focus last element otherwise focus previous element
      prevIndex - 1 < 0
        ? (prevIndex = focusableElements.length - 1)
        : (prevIndex -= 1)

      focusableElements[prevIndex].focus()

      return evt.preventDefault()
    }
  }

  // map of keyboard listeners
  const keyListenersMap = new Map([
    [27, handleEscape],
    [9, handleTab],
  ])

  const handleKeydown = evt => {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(evt.keyCode)

    // call the listener if it exists
    return listener && listener(evt)
  }

  const handleClick = evt => {
    // Close modal on bg click
    if (evt) evt.preventDefault()
    closeOverlay()
  }

  // Close modal on 'esc' keypress
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  })

  useEffect(() => {
    // Cache current activeElement
    const { activeElement } = document

    return () => activeElement.focus()
  }, [])

  useLockBodyScroll()

  // fixes the body scroll not resetting when the modal closes.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const bodyOverflowHidden =
        window.getComputedStyle(document.body).overflow === 'hidden'

      return () =>
        bodyOverflowHidden ? (document.body.style.overflow = '') : ''
    }
  }, [])

  let maxHeight = ''
  switch (body) {
    case 'Venue Enquiry':
      maxHeight = 'max-h-638px'
      break
    case 'General Enquiry':
      maxHeight = 'max-h-577px'
      break
    case 'Coaching Enquiry':
      maxHeight = 'max-h-641px'
      break
    case 'Retreat Enquiry - Early':
      maxHeight = 'max-h-[685px]'
      break
    case 'Retreat Enquiry - Standard':
      maxHeight = 'max-h-[685px]'
      break
    case 'Booking Enquiry - Standard':
      maxHeight = 'max-h-[625px]'
      break
    case 'Booking Enquiry - Early':
      maxHeight = 'max-h-[625px]'
      break
    default:
      maxHeight = 'max-h-600px'
      break
  }

  return (
    <motion.aside
      className="fixed inset-0 z-30 grid bg-black place-items-center md:p-16 lg:p-24 bg-opacity-90"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      role="dialog"
      aria-modal="true"
    >
      <motion.div
        initial="hidden"
        animate={submitted ? 'submitted' : 'visible'}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
          submitted: { opacity: 1, y: 0, height: 380 },
        }}
        transition={{ duration: 0.2 }}
        className={`w-full ${width} h-full ${maxHeight} m-auto bg-gray-100 md:rounded-8 self-stretch overflow-hidden`}
        ref={content}
        onClick={evt => evt.stopPropagation()}
      >
        <SimpleBar className="h-full overflow-auto" autoHide={false}>
          {contentType === 'form' && (
            <>
              <div className="flex items-center">
                <button
                  type="button"
                  className="sticky flex items-center mt-4 ml-auto mr-4 leading-none top-4 text-brown-500"
                  onClick={closeOverlay}
                >
                  <span className="mr-1 mt-3px font-sm">Close</span>
                  <IoMdClose size="1.5em" />
                </button>
              </div>
              <Form setSubmitted={setSubmitted} />
            </>
          )}
          {contentType === 'popup' && <Popup />}
        </SimpleBar>
      </motion.div>
    </motion.aside>
  )
}

export default Content
